import { environment } from '../../environments/environment';

export class Urls {

  // hardcodedServerURL = 'https://testadmin.bhoju.com';
  hardcodedServerURL = 'https://adminpanelconnect.bhoju.com';
  serverURL = (environment.serverUrl === null ? this.hardcodedServerURL : environment.serverUrl);

  // hardcodedServerAPI = '/rst/'; // firestixAPI_dev_2/ ulluCore/ rst/
  hardcodedServerAPI = '/userApi'; // firestixAPI_dev_2/ ulluCore/ rst/
  serverAPI = (environment.serverAPI === null ? this.hardcodedServerAPI : environment.serverAPI);
  apiVersion = 'api/v1/';
  apiVersion2 = 'api/v2/';
  baseURL = `${this.serverURL}${this.serverAPI}`;
  baseWithApiVersionURL = `${this.baseURL}${this.apiVersion}`;
  // baseWithApiVersion2URL = `${ this.baseURL }${ this.apiVersion2 }`;
  baseWithApiVersion2URL = `${this.baseURL}`;


  // File URL
  // ----------------------------------------------------------------

  downloadFileURL = environment.cdn === null ? `${this.baseWithApiVersionURL}files/download/` : environment.cdn;
  downloadSubtitleFileURL = `${this.baseWithApiVersionURL}files/download/`;
  landscapePosterFileURL = `${this.baseWithApiVersionURL}files/downloadLandscapePoster`;
  portraitPosterFileURL = `${this.baseWithApiVersionURL}files/downloadPortraitPoster`;
  // uploadFileURL = `${ this.baseWithApiVersion2URL }/user/updateProfile`;
  uploadFileURL = `${this.baseWithApiVersion2URL}/user/updateProfileWeb`;

  // Authenticate Service URLS
  // ----------------------------------------------------------------

  OAuthLoginURL = `${this.baseWithApiVersion2URL}/oauth/token`;
  // registerUserURL = `${ this.baseWithApiVersionURL }consumer/register`;
  registerUserURL = `${this.baseWithApiVersion2URL}/registerManual`;
  loginAccessUpdateURL = `${this.baseWithApiVersion2URL}/register`;
  // loginAccessUpdateURL = `${ this.baseWithApiVersion2URL }/registerManual`;
  OAuthLogoutURL = `${this.baseWithApiVersion2URL}/token/logout`;
  sendOTPURL = `${this.baseWithApiVersion2URL}/otp/sendRegisterOTP`;
  verifyOTPURL = `${this.baseWithApiVersion2URL}/otp/validate`;
  sendRequestForForgottenPasswordURL = `${this.baseWithApiVersion2URL}/forgot_password_web`;
  resetForgottenPasswordURL = `${this.baseWithApiVersion2URL}/reset_password_web`;
  checkUserLogin =`${ this.baseWithApiVersion2URL }/checkUserLoggedIn`;
  // resetForgottenPasswordURL = `${ this.baseWithApiVersionURL }consumer/resetPassword`;

  // Home Service URLS
  // ----------------------------------------------------------------

  // Home Service URLS
  // ----------------------------------------------------------------
  fetchForSliderMediaListURL = `${ this.baseWithApiVersion2URL }/home_sliders_web`;
  fetchForRecentlyWatchedListURL = `${ this.baseWithApiVersion2URL }/recently_watch`;
  fetchForHomeScreenMediaURL = `${ this.baseWithApiVersion2URL }/contentBycatAndPopular`;

  // Upcoming Media Service URLS
  // ----------------------------------------------------------------
  fetchForUpcomingMediaList = `${this.baseWithApiVersion2URL}media/upcomingMedia/fetchAllActive/page`;

  // Media Service URLS
  // ----------------------------------------------------------------
  fetchForMediaByTitleYearSlugURL = `${this.baseWithApiVersion2URL}/videoViewBySlug`;
  // searchForMediaByTitleURL = `${ this.baseWithApiVersionURL }media/v2/client/search`;
  searchForMediaByTitleURL = `${this.baseWithApiVersion2URL}/browse_web_videos`;
  fetchForPopularMediaListURL = `${this.baseWithApiVersionURL}media/v2/getAllPopularMediaContent`;
  fetchForMediaListByMediaCategoryTitleURL = `${this.baseWithApiVersion2URL}media/fetchMediaContentByUiCategoryTitle`;
  checkWhetherCurrentUserIsAllowedToWatchMediaURL = `${this.baseWithApiVersionURL}consumer/media/token/isAllowed`;
  sendMediaPlaybackEventURL = `${this.serverURL}/evnts/api/v1/kookuevents/addNewKookuEvent`;
  fetchForVastPropertiesURL = `${this.baseWithApiVersionURL}ads/fetchVastProperties`;

  // fetchForMediaURL = `${ this.baseWithApiVersion2URL }/videoViewBySlug`;

    // User Service URLS
  // ----------------------------------------------------------------
  // fetchForCurrentUserDetailsURL = `${ this.baseWithApiVersionURL }user/currentUser/detailsFull/secure?fields=consumerSubscription.*`;
  fetchForCurrentUserDetailsURL = `${ this.baseWithApiVersion2URL }/profileUser`;
  // changeUserProfileDetailsURL = `${ this.baseWithApiVersionURL }user/changeProfileConsumer/secure`;
  changeUserProfileDetailsURL = `${ this.baseWithApiVersion2URL }/user/updateProfileWeb`;
  changeUserPasswordURL = `${ this.baseWithApiVersion2URL }/user/changePasswordConsumer`;
  updateUserFCMTokenURL = `${ this.baseWithApiVersionURL }consumer/updateFCM/secure`;

  // SubscriptionPackage Service URLS
  // -----------------------------------------------------------------
  // fetchForSubscriptionListURL = ${ this.baseWithApiVersionURL }subscribe/getAllSubscriptionPackages/web;
  fetchForSubscriptionListURL = `${ this.baseWithApiVersion2URL }/subscription_plans_web`;
  // prepareSubscriptionOrderForPayTMURL = ${ this.baseWithApiVersionURL }payments/payTM/prepareOrder/secure/;
  prepareSubscriptionOrderForPayTMURL = `${ this.baseWithApiVersion2URL }/paytm_transaction`;
  prepareSubscriptionOrderForAgreepayURL = `${ this.baseWithApiVersion2URL }/agrepay_transaction`;
  prepareSubscriptionOrderForAirpayURL = `${ this.baseWithApiVersion2URL }/airPayWeb`;
  // prepareSubscriptionOrderForPayTMURL = ${ this.baseWithApiVersion2URL }/payment;
  // payTMRedirectURL = ${ this.baseWithApiVersionURL }payments/payTM/preparePaytmRequestWEB/;
  // payTMRedirectURL = ${ this.baseWithApiVersion2URL }/payment/status;
  ageVerifyUrl = `${ this.baseWithApiVersion2URL }/userAge`;

  prepareSubscriptionOrderForPaypalIndiaURL = `${ this.baseWithApiVersionURL }payments/braintree/india/prepareOrderWithClientToken/secure/`;
  paypalIndiaCheckoutURL = `${ this.baseWithApiVersionURL }payments/braintree/india/checkout/secure`;
  prepareSubscriptionOrderForPaypalURL = `${ this.baseWithApiVersionURL }payments/braintree/prepareOrderWithClientToken/secure/`;
  paypalCheckoutURL = `${ this.baseWithApiVersionURL }payments/braintree/checkout/secure`;

  prepareSubscriptionOrderForStripeURL = `${ this.baseWithApiVersionURL }payments/stripe/prepareOrder/secure`;

 // Coupon Service URLS
  // -----------------------------------------------------------------
  validateCouponCodeURL = `${ this.baseWithApiVersionURL }coupons/validateCouponCode/secure`;
  redeemCouponURL = `${ this.baseWithApiVersionURL }payments/ullu/consumeCoupon/secure`;

  // Support Service URLS
  // -----------------------------------------------------------------
  verifyCaptchaURL = `${ this.baseWithApiVersionURL }user/recaptcha/validate`;
  sendSupportRequestForUnLoggedUserURL = `${ this.baseWithApiVersionURL }consumer/mailToConsumerSupport/unlogged`;
  // sendSupportRequestForLoggedUserURL = `${ this.baseWithApiVersionURL }consumer/mailToConsumerSupport/secure`;
  // sendSupportRequestForLoggedUserURL = `${ this.baseWithApiVersion2URL }/tickets`;
  sendSupportRequestForLoggedUserURL = `${ this.baseWithApiVersion2URL }/createWebTickets`;

  // UI Content Service URLS
  // -----------------------------------------------------------------
  fetchForCountryCodeListURL = `./assets/local_data/country-codes.json`;
  fetchForUIContentURL = `${ this.baseWithApiVersion2URL }contents/fetchClientContent`;
  fetchForServerStatusURL = `https://kooku-system-status.s3.ap-south-1.amazonaws.com/prod/kooku-version.json`;
  fetchForAppVersionURL = `${ this.baseWithApiVersion2URL }version/getApplicationVersion`;

  //For Cookies
  //---------------------------------------------
  getCookiesFromServer = `https://adminpanelconnect.bhoju.com/userApi/getAllCookies`;
  // getCookiesFromServer = `https://testadmin.bhoju.com/userApi/getAllCookies`;
  //getTestVideoUrlForMedia = `https://media1.rokkt.tv/userApi/testVideo`;

  // For Country, state and city
  fetchForCountryStateCityURL = `${ this.baseWithApiVersion2URL }/getUsersState`;
  fetchForCountryPaymentTypeURL = `${ this.baseWithApiVersion2URL }/paymentGatewayCountryWise`;

  fetchLongUrlByShortCodeURL = `${ this.baseWithApiVersion2URL }/longUrl`;
  incrementURLHitByCode = `${ this.baseWithApiVersion2URL }/incrementURLHit`;

    /** Privacy policy page */
  fetchPrivacyUIContent = `${ this.baseWithApiVersion2URL }/getPageByID`;

  constructor() {
  }
}
