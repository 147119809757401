import { Router } from '@angular/router';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AppRoutes } from '../../models/core/enums/app-routes.enum';
import { AppStoreDialogComponent } from '../../dialogs/app-store-dialog/app-store-dialog.component';
import { MatDialog } from '@angular/material';
import { ViewportScroller } from '@angular/common';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})



export class FooterComponent implements OnInit {
  // @ViewChild("ftrlinks", { static: true }) ftrlinks:ElementRef;
  appRoutes = AppRoutes;
  hideArrow: boolean = false;

  
  nonClicked: boolean=true;
  Clicked: boolean=false;
  otherButton:boolean=true;
  isDisplay = false;

  // @HostListener('document:click', ['$event'])
  // clickout(event){
  //   if (this.ftrlinks.nativeElement.contains(event.target)){
  //     this.toggleDisplay();{
  //       this.Clicked = true;
  //       this.nonClicked = false;
  //     }
  //   }else{
  //     this.toggleDisplay();{
  //       this.Clicked = false;
  //       this.nonClicked = true;
  //     }
  //   }
  // }
  getName(){
    this.nonClicked = true;
    this.Clicked = false;
    this.isDisplay = false;
  }

  toggleDisplay(){
    if(localStorage.getItem('nonClicked') == 'true'){
      this.nonClicked = false;
      this.Clicked = true;
      var element = document.getElementById("Home");
      element.classList.remove("active");
      var element = document.getElementById("Brows");
      element.classList.remove("active");
      var element = document.getElementById("Subscr");
      element.classList.remove("active");
      var element = document.getElementById("Logi");
      element.classList.remove("active");
      
      localStorage.setItem('nonClicked', 'false' );
      
    }
    else{
      this.nonClicked = true;
      this.Clicked = false;
      var element = document.getElementById("Home");
      element.classList.remove("active");
      var element = document.getElementById("Brows");
      element.classList.remove("active");
      var element = document.getElementById("Subscr");
      element.classList.remove("active");
      var element = document.getElementById("Logi");
      element.classList.remove("active");
      

      localStorage.setItem('nonClicked', 'true' );
    }

    this.isDisplay = !this.isDisplay;
  }


  constructor(private matDialog: MatDialog, private viewportScroller: ViewportScroller, private router: Router) {
  }

   // Smooth Scroll option
  // ---------------------------------------------------------------
  onClickTopScroll(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit(): void {
    // console.log("URL",this.appRoutes);
    localStorage.setItem('nonClicked', 'true' );
    
    
    this.router.events.subscribe(
      (event: any) => {
        if(this.appRoutes.SUBSCRIBE === this.router.url || this.appRoutes.SUPPORT === this.router.url){
            this.hideArrow = true;
        } else {
          this.hideArrow = false;
        }
      }
    );
    // Show App Store Links on Mobile devices.
    if (window.innerWidth <= 768) {
      this.matDialog.open(AppStoreDialogComponent, {
        panelClass: 'app-store-dialog',
        minWidth: window.innerWidth <= 500 ? '96vw' : '50vw',
        position: { bottom: 'bottom' },
        hasBackdrop: false
      });
    }
    
  }

}
